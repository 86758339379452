'use client';

import { SessionProvider } from 'next-auth/react';
import type { ComponentProps } from 'react';

type NextAuthProviderProps = {
	children: React.ReactNode;
} & ComponentProps<typeof SessionProvider>;

function NextAuthProvider({ children, ...rest }: NextAuthProviderProps) {
	return <SessionProvider {...rest}>{children}</SessionProvider>;
}

export default NextAuthProvider;
