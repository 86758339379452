import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

// Extend the default tailwindMerge with custom logic
const customTailwindMerge = extendTailwindMerge({
	extend: {
		// theme: {
		// 	colors: ['primary-red', 'primary-blue', 'primary-yellow'],
		// },
		classGroups: {
			'bg-image': ['bg-noise-light', 'bg-noise-dark'],
		},
	},
});

export function cn(...inputs: ClassValue[]) {
	return customTailwindMerge(clsx(inputs));
}
