import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../../../shared/utils/utils';

const buttonVariants = cva(
	'inline-flex items-center cursor-pointer justify-center xs:whitespace-nowrap rounded-[24px] ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-80 gap-2 leading-none [&_span]:whitespace-nowrap [&_span]:leading-none border-solid disabled:bg-gray-300 disabled:border-gray-300',
	{
		variants: {
			variant: {
				primary: 'leading-none',
				outline: '!bg-transparent leading-none border-2 border-solid',
				ghost: 'hover:bg-accent bg-transparent disabled:bg-transparent disabled:border-transparent',
				link: 'underline-offset-4 font-medium',
			},
			color: {
				red: 'bg-primary-red text-primary-red-foreground border-primary-red',
				yellow: 'bg-primary-yellow text-primary-yellow-foreground border-primary-yellow',
				blue: 'bg-primary-blue text-primary-blue-foreground border-primary-blue',
				light: 'bg-white text-gray-800 border-white',
				dark: 'bg-gray-800 text-white border-gray-800',
				transparent:
					'bg-transparent text-gray-800 border-transparent disabled:bg-transparent disabled:border-transparent',
			},
			size: {
				small: 'px-8 py-3 text-sm font-bold [&>svg]:h-4 [&>svg]:w-4 border',
				medium: 'px-12 sm:px-14 font-medium py-5 border-2',
				large: 'sm:px-20 font-bold py-3.5 px-5.5 xs:py-[18px] sm:py-[22px] sm:text-[22px] text-base xs:text-lg xs:px-12 border-[3px]',
				icon: 'h-10 w-10 border-0',
			},
		},
		defaultVariants: {
			variant: 'primary',
			size: 'medium',
			color: 'dark',
		},
		compoundVariants: [
			{
				variant: 'outline',
				color: 'red',
				class: 'text-primary-red border-primary-red',
			},
			{
				variant: 'outline',
				color: 'yellow',
				class: 'text-primary-yellow border-primary-yellow',
			},
			{
				variant: 'outline',
				color: 'blue',
				class: 'text-primary-blue border-primary-blue',
			},
			{
				variant: 'outline',
				color: 'light',
				class: 'text-white border-white',
			},
			{
				variant: 'outline',
				color: 'dark',
				class: 'text-gray-800 border-gray-800',
			},
		],
	}
);

export interface ButtonProps
	extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, color, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'button';
		return (
			<Comp className={cn(buttonVariants({ variant, size, color, className }))} ref={ref} {...props} />
		);
	}
);
Button.displayName = 'Button';

export { Button, buttonVariants };
