'use client';
import { cn } from '@features/shared/utils/utils';
import { Button } from '@features/webshop/shared/ui/Button';
import { IconMenu } from '@tabler/icons-react';
import { useClickAway } from '@uidotdev/usehooks';
import Link from 'next/link';
import { $path } from 'next-typesafe-url';
import CloseNavIcon from './CloseNavIcon';
import { BtnSizeEnum, BtnVariantEnum, BtnColorEnum } from '@features/api/client';
import { useAtom } from 'jotai';
import { isMobileMenuOpenAtom } from './state/isMobileMenuOpenAtom';

type NavMenuProps = {
	children: React.ReactNode;
};

function NavMenu({ children }: NavMenuProps) {
	const [isOpen, setIsOpen] = useAtom(isMobileMenuOpenAtom);

	const ref = useClickAway<HTMLDivElement>(() => {
		if (!isOpen) return;

		setIsOpen(false);
	});

	return (
		<div ref={ref}>
			<Button
				variant={BtnVariantEnum.Ghost}
				size={BtnSizeEnum.Icon}
				color={BtnColorEnum.Light}
				onClick={() => setIsOpen(!isOpen)}
				className=" xl:hidden">
				{isOpen ? <CloseNavIcon /> : <IconMenu />}
			</Button>
			<div
				className={cn(
					'relative max-xl:container max-xl:absolute max-xl:left-0 max-xl:right-0 max-xl:top-full max-xl:flex max-xl:h-screen max-xl:-translate-x-full max-xl:flex-col max-xl:gap-12 max-xl:bg-white max-xl:py-4 max-xl:transition-transform max-xl:duration-300 max-xl:ease-in-out max-md:z-50 ',
					isOpen && 'overflow-scroll max-xl:translate-x-0'
				)}>
				{children}
				<Link
					href={$path({ route: '/frontoffice' })}
					className="block md:hidden"
					onClick={() => setIsOpen(false)}>
					<Button
						variant={BtnVariantEnum.Primary}
						size={BtnSizeEnum.Small}
						color={BtnColorEnum.Blue}
						className="w-full">
						Isprobaj besplatno
					</Button>
				</Link>
			</div>
		</div>
	);
}

export default NavMenu;
