'use client';

import React, { useRef } from 'react';
import useStickyHeader from './useStickyHeader';

type StickyHeaderProps = {
	children: React.ReactNode;
};

function StickyHeader({ children }: StickyHeaderProps) {
	const headerRef = useRef<HTMLDivElement | null>(null);

	useStickyHeader(headerRef);

	return (
		<div className="relative z-[100] border-solid border-gray-150 will-change-transform" ref={headerRef}>
			{children}
		</div>
	);
}

export default StickyHeader;
