import React, { useEffect, useRef } from 'react';

function useStickyHeader(headerRef: React.MutableRefObject<HTMLDivElement | null>) {
	const currentScrollTop = useRef(0);
	const setCurrentScrollTop = (data: number) => {
		currentScrollTop.current = data;
	};
	const headerBounds = useRef<DOMRectReadOnly | null>(null);
	const setHeaderBounds = (data: DOMRectReadOnly) => {
		headerBounds.current = data;
	};

	const hide = () => {
		if (!headerRef.current) return;

		headerRef.current.classList.add('-translate-y-full', 'sticky', 'top-0');
	};

	const reveal = () => {
		if (!headerRef.current) return;

		headerRef.current.classList.add(
			'sticky',
			'top-0',
			'transition-transform',
			'duration-300',
			'ease-out',
			'border-b'
		);
		headerRef.current.classList.remove('-translate-y-full', 'relative');
	};

	const reset = () => {
		if (!headerRef.current) return;

		headerRef.current.classList.remove(
			'-translate-y-full',
			'sticky',
			'top-0',
			'transition-transform',
			'duration-300',
			'ease-out',
			'border-b'
		);
		headerRef.current.classList.add('relative');
	};

	const onScroll = () => {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

		if (
			scrollTop > currentScrollTop.current &&
			headerBounds?.current &&
			scrollTop > headerBounds?.current?.bottom
		) {
			requestAnimationFrame(hide);
		} else if (
			scrollTop < currentScrollTop.current &&
			headerBounds?.current &&
			scrollTop > headerBounds?.current?.bottom
		) {
			requestAnimationFrame(reveal);
		} else if (headerBounds?.current && scrollTop <= headerBounds?.current?.top) {
			requestAnimationFrame(reset);
		}

		setCurrentScrollTop(scrollTop);
	};

	useEffect(() => {
		const headerElement = headerRef.current;
		const observer = new IntersectionObserver((entries, obs) => {
			const firstEntry = entries[0];
			if (!firstEntry) return;

			setHeaderBounds(firstEntry.intersectionRect);
			obs.disconnect();
		});

		if (headerElement) observer.observe(headerElement);

		window.addEventListener('scroll', onScroll, false);

		return () => {
			if (headerElement) observer.unobserve(headerElement);
			window.removeEventListener('scroll', onScroll);
		};
	}, []);
}

export default useStickyHeader;
