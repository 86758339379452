'use client';

import { CldImage, type CldImageProps } from 'next-cloudinary';

type ImageProps = CldImageProps & { alt: string; src: string; width: number; height: number };

const CloudinaryImage = (props: ImageProps) => {
	const isCorrectUrl = props.src.includes('/v1/');
	const publicId = props.src.split('/').at(-1);
	const src = isCorrectUrl ? props.src : publicId || props.src;

	return <CldImage {...props} src={src} />;
};

export default CloudinaryImage;
