'use client';

import '@styles/text.scss';
import '@styles/animations.scss';

import { ApiClientProvider } from '@features/api/ApiClientContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactGA from 'react-ga4';
import { onCLS, onINP, onLCP } from 'web-vitals';

import sendToGoogleAnalytics from '../utils/sendToGoogleAnalytics';
import { useEffect } from 'react';

const queryClient = new QueryClient();

type SharedClientProvidersProps = { children: React.ReactNode };

function SharedClientProviders({ children }: SharedClientProvidersProps) {
	useEffect(() => {
		if (!process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID) return;

		ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID);

		onCLS(sendToGoogleAnalytics);
		onINP(sendToGoogleAnalytics);
		onLCP(sendToGoogleAnalytics);
	}, []);

	return (
		<ApiClientProvider>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</ApiClientProvider>
	);
}

export default SharedClientProviders;
