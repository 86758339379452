'use client';

import { annotate } from 'rough-notation';
import { useEffect, useRef, useState } from 'react';

function RoughNotation({ children, disabled }: { children: React.ReactNode; disabled?: boolean }) {
	const [annotatedElements, setAnnotatedElements] = useState<HTMLElement[]>([]);
	const wrapperRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!wrapperRef.current) return;

		const handDrawnUnderlineElements = wrapperRef.current.querySelectorAll(
			'.hand-drawn-underline'
		) as NodeListOf<HTMLElement>;

		handDrawnUnderlineElements.forEach((element) => {
			if (annotatedElements.includes(element)) return;

			const annotation = annotate(element, {
				type: 'underline',
				color: 'var(--primary-yellow)',
				strokeWidth: 6,
				multiline: true,
			});
			annotation.show();
			setAnnotatedElements((prev) => [...prev, element]);
		});
	}, []);

	if (disabled) return children;

	return <span ref={wrapperRef}>{children}</span>;
}

export default RoughNotation;
