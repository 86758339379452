import ReactGA from 'react-ga4';

type sendToGoogleAnalyticsProps = {
	name: string;
	delta: number;
	value: number;
	id: string;
};

function sendToGoogleAnalytics({ name, delta, value, id }: sendToGoogleAnalyticsProps) {
	ReactGA.send({
		hitType: 'event',
		value: delta,
		metric_id: id,
		metric_value: value,
		metric_delta: delta,
	});
}

export default sendToGoogleAnalytics;
